import React, {useEffect, useState} from "react";
import PortalLayout from "../../layouts/PortalLayout";
import {useNavigate} from "react-router-dom";
import companyService from "../../api/services/CompanyService";
import DeleteModal from "../../components/DeleteModal";
import Loading from "../../components/Loading";

const Company = () => {
    const navigate = useNavigate();
    const [deleteID, setDeleteID] = useState()
    const [open, setOpen] = useState(false)
    const [callDelete, setCallDelete] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState();
    console.log("data", data);

    useEffect(() => {
        setLoading(true)
        const fetchCompanies = async () => {
            const response = await companyService.fetchAll();
            setData(response?.companies);
            setLoading(false)
            setDeleting(false)
        };
        fetchCompanies();
    }, [callDelete]);

    const deleteCompany = async () => {
        setDeleting(true)
        const response = await companyService.delete(deleteID);
        if (response.success) {
            setCallDelete(!callDelete)
            setOpen(false)
        }
    }

    const initiateDelete = (id) => {
        setOpen(!open)
        setDeleteID(id)
    }

    return (
        <PortalLayout>
            {loading ?
                <Loading/>
                :
                <div>
                    <DeleteModal open={open} setOpen={setOpen} deleteFunction={deleteCompany} deleting={deleting}/>
                    <h1 className="text-center text-[25px] font-[800] uppercase mt-5">
                        Companies
                    </h1>
                    <div className="w-full justify-normal flex flex-col">
                        <div className="flex justify-center mt-[3rem] w-[90%] m-auto">
                            <input
                                name="search"
                                type="search"
                                id="search"
                                placeholder="Search Here"
                                className="border-2 border-gray-600 px-[1rem] py-2 rounded-[1rem] w-[20rem] mr-auto focus:outline-none focus:border-gray-900"
                            />
                            <button
                                onClick={() => navigate("/companies/addcompanies")}
                                className="bg-black py-2 px-[1rem] rounded-[1.5rem] w-[150px] font-[600] ml-auto text-white cursor-pointer"
                            >
                                Add New
                            </button>
                        </div>
                        <table className=" rounded-xl p-5 bg-white w-[90%] m-auto mt-6">
                            <thead>
                            <tr className="uppercase text-sm leading-normal w-full">
                                <th className="py-[2%] border-r-[1px] border-b-[2px] border-b-black text-center text-[13px] w-[2%]">
                                    Id
                                </th>
                                <th className="py-[2%] border-r-[1px] border-b-[2px] border-b-black text-center text-[13px] w-[7%]">
                                    Name
                                </th>
                                <th className="py-[2%] border-r-[1px] border-b-[2px] border-b-black text-center text-[13px] w-[3%]">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.map((val, i) => (
                                <tr key={i} className="text-black text-sm w-full">
                                    <td className="py-[2%] w-[2%] border-r-[1px] border-t-[1px] text-center font-bold text-blue-500">
                                        {val.id}
                                    </td>
                                    <td className="py-[2%] w-[2%] border-r-[1px] border-t-[1px] text-center font-bold text-[12px]">
                                        {val.name}
                                    </td>
                                    <td className="py-[2%] w-[2%] border-r-[1px] border-t-[1px] ">
                                        <div className="flex items-center justify-center">
                                            <div className="w-4 mr-2 cursor-pointer hover:scale-125">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="blue"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="w-4 ml-2 cursor-pointer hover:scale-125"
                                                 onClick={() => initiateDelete(val.id)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="red"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </PortalLayout>
    );
};

export default Company;
