import axiosInstance from "./axiosInstance";

const companyService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/companies/create', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    update: async (payload) => {
        try {
            const response = await axiosInstance.put('/companies/update', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    fetch: async (id) => {
        try {
            const response = await axiosInstance.get('/companies/single/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/companies/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/companies/delete/' + id)
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default companyService
