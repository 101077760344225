import axiosInstance from "./axiosInstance";

const designationService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/designations/create', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    update: async (payload) => {
        try {
            const response = await axiosInstance.put('/designations/update', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    fetch: async (id) => {
        try {
            const response = await axiosInstance.get('/designations/single/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/designations/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/designations/delete/' + id)
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default designationService
