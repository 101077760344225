import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import {useLocation} from "react-router-dom";
import designationService from "../../api/services/DesignationService";
import workerService from "../../api/services/EmployeeService";
import Loading from "../../components/Loading";

const EmployeeDetails = () => {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
            setLoading(true)
            const fetchDetails = async () => {
                const response = await workerService.fetchDetails(params.get("id"));
                console.log(response.worker)
                setData(response.worker);
                setLoading(false)
            };
            fetchDetails();
    }, []);

    return (
        <>
            {loading ?
                <Loading/>
                :
                <div className={`bg-gray-100 h-lvh py-10 px-5`}>
                    <h1 className='text-center text-[25px] font-[800] uppercase'>Details</h1>
                    <div
                        className="w-[60%] m-auto bg-white shadow-md rounded-xl px-[8rem] pt-10 pb-8 flex flex-col my-5 text-center items-center justify-center">
                        <h1 className={`text-blue-700 font-bold text-[18px]`}>{data.name}</h1>
                        <h1 className={`text-black font-bold text-[13px]`}>{data.designation_name}</h1>
                        <p className={`text-black font-semibold text-[15px] border-[1px] border-green-800 px-8 py-2 rounded-lg mt-3`}>Iqama: {data.iqama}</p>
                        <p className={`text-gray-600 font-semibold text-[17px] mt-2`}>{data.company_name}</p>
                    </div>
                    <div
                        className="w-[60%] m-auto bg-white shadow-md rounded-xl px-[8rem] pt-10 pb-8 flex flex-col my-5">
                        <h1 className={`text-green-800 font-bold text-[20px] text-center uppercase`}>Trainings</h1>
                        <div className={`grid grid-cols-2 gap-2 mt-5`}>
                            {data?.trainings?.map(value => (
                                <div
                                    className={`flex flex-col w-full bg-gray-100 rounded-lg px-5 py-8 justify-center items-center`}>
                                    <p className={`font-bold text-[17px]`}>{value.name}</p>
                                    <div className={`gap-1 items-center justify-center`}>
                                        <div className={`text-center`}>
                                            <p
                                                className={`text-[12px] text-green-800 pt-[3px] mt-4 font-bold`}>Training
                                                Finished on
                                            </p>
                                            <p className={`text-[14px] text-gray-700 mt-1`}>{moment(value.end).format("ll")}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EmployeeDetails;
