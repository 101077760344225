import React from 'react'
import PortalLayout from '../layouts/PortalLayout'

const Home = () => {
  return (
    <PortalLayout>
        Home
    </PortalLayout>
  )
}

export default Home