import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Employees from './Pages/Employee/Employees';
import EmployeesAddEdit from './Pages/Employee/EmployeesAddEdit';
import Company from './Pages/Company/Company';
import CompanyAddEdit from './Pages/Company/CompanyAddEdit';
import Training from './Pages/Training/Training';
import TrainingAddEdit from './Pages/Training/TrainingAddEdit';
import Designation from './Pages/Designation/Designation';
import DesignationAddEdit from './Pages/Designation/DesignationAddEdit';
import EmployeeTrainings from "./Pages/Employee/EmployeeTrainings";
import EmployeeDetails from "./Pages/Employee/EmployeeDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/employees/addemployees" element={<EmployeesAddEdit />} />
        <Route path="/companies" element={<Company />} />
        <Route path="/companies/addcompanies" element={<CompanyAddEdit />} />
        <Route path="/trainings" element={<Training />} />
        <Route path="/trainings/addtrainings" element={<TrainingAddEdit />} />
        <Route path="/designations" element={<Designation />} />
        <Route path="/designations/adddesignation" element={<DesignationAddEdit />} />
        <Route path="/employees/trainings" element={<EmployeeTrainings />} />
        <Route path="/details" element={<EmployeeDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
