import React, { useEffect, useState } from 'react'
import PortalLayout from '../../layouts/PortalLayout'
import companyService from '../../api/services/CompanyService';
import {useNavigate} from "react-router-dom";
import toast,{ Toaster } from 'react-hot-toast';

const CompanyAddEdit = ({ edit = false }) => {

  const [name, setName] = useState("")
  const [data, setData] = useState();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (name){
    try {
      await companyService.create({ name }).then(res => navigate('/companies'));
    } catch (error) {
      console.error("Error creating designation:", error);
    }
   }else{
     toast.error("Please Enter Company Name",{
      duration: 3000,
      position: 'top-center',
      id: 3
     })
   }
  };


  useEffect(() => {
    const fetchSingle = async () => {
      const response = await companyService.fetch();
      setData(response.companies);
    };
    fetchSingle();
  }, []);

  return (
    <PortalLayout>
        <h1 className='text-center text-[25px] font-[800] mt-5 uppercase'>{edit ? "Edit Company" : "Add Company"}</h1>
      <div className="w-[90%] m-auto bg-white shadow-md rounded-xl px-[10rem] pt-6 pb-8 mb-4 flex flex-col my-2">
        <div className="-mx-3 mt-[-1.2rem] mb-6">
          <div className="w-[70%] m-auto px-3 mb-6 md:mb-0 mt-5">
            <label className="text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4">
              Company
            </label>
            <input onChange={(e) => setName(e.target.value)} type="text" name="title" id="floating_email" className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer" placeholder="Enter Company Name" />
          </div>
        </div>
        <div className='flex justify-center'>
          <button onClick={handleSubmit} className='bg-black hover:font-bold text-white font-[600] py-2 px-[3rem] mt-4 rounded-lg cursor-pointer'>Submit</button>

        </div>
      </div>
    </PortalLayout>
  )
}

export default CompanyAddEdit
