import axiosInstance from "./axiosInstance";

const trainingService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/trainings/create', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    update: async (payload) => {
        try {
            const response = await axiosInstance.put('/trainings/update', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    fetch: async (id) => {
        try {
            const response = await axiosInstance.get('/trainings/single/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/trainings/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/trainings/delete/' + id)
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default trainingService
