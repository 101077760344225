import React, {useState} from 'react'
import PortalLayout from '../../layouts/PortalLayout'
import designationService from '../../api/services/DesignationService'
import {useNavigate} from "react-router-dom";
import toast from 'react-hot-toast';

const DesignationAddEdit = ({edit = false}) => {

    const [name, setName] = useState("")

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (name) {
            try {
                await designationService.create({name}).then(res => navigate('/designations'));
            } catch (error) {
                console.error("Error creating designation:", error);
            }
        } else {
            toast.error('Please Enter Designation',{
                duration: 3000,
                position: 'top-center',
                id: 1
            })
        }
    };

    return (
        <PortalLayout>
            <h1 className='text-center text-[25px] font-[800] mt-5 uppercase'>{edit ? "Edit Designation" : "Add Designation"}</h1>
            <div className="w-[90%] m-auto bg-white shadow-md rounded-xl px-[10rem] pt-6 pb-8 mb-4 flex flex-col my-2">
                <div className="-mx-3 mt-[-1.2rem] mb-6">
                    <div className="w-[70%] m-auto px-3 mb-6 md:mb-0 mt-5">
                        <label className="text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4">
                            Name
                        </label>
                        <input onChange={(e) => setName(e.target.value)} type="text" name="title" id="floating_email"
                               className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer"
                               placeholder="Enter Your Name"/>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <button onClick={handleSubmit}
                            className='bg-black hover:font-bold text-white font-[600] py-2 px-[3rem] mt-4 rounded-lg cursor-pointer'>Submit
                    </button>
                </div>
            </div>
        </PortalLayout>
    )
}

export default DesignationAddEdit
