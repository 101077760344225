import JoditEditor from 'jodit-react'
import React, {useEffect, useState} from 'react'
import Select from "react-select";
import PortalLayout from '../../layouts/PortalLayout'
import designationService from '../../api/services/DesignationService';
import companyService from '../../api/services/CompanyService';
import employeeService from "../../api/services/EmployeeService";
import {useNavigate} from "react-router-dom";
import toast from 'react-hot-toast';

const EmployeesAddEdit = ({edit = false}) => {
    const style = 'text-left tracking-wide text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4'

    const [data, setData] = useState({
        name: '',
        company: '',
        designation: '',
        iqama: '',
        gender: 'Male',
        age: '',
        phone: ''
    })
    const [designations, setDesignations] = useState([])
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState()
    const [designation, setDesignation] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        const fetchDesignations = async () => {
            const response = await designationService.fetchAll();
            setDesignations(response.designations);
        };
        fetchDesignations();

        const fetchCompanies = async () => {
            const response = await companyService.fetchAll();
            setCompanies(response.companies);
        };
        fetchCompanies();
    }, []);

    useEffect(() => {
        console.log(data)
    }, [data]);

    useEffect(() => {
        setData(prevState => ({...prevState, company: company?.id}))
    }, [company]);

    useEffect(() => {
        setData(prevState => ({...prevState, designation: designation?.id}))
    }, [designation]);

    const submit = () => {
        if(data.name){
            if(data.company){
                if(data.designation){
                    if(data.iqama){
                        if(data.gender){
                            if(data.age){
                                if(data.phone){ 
                                        employeeService.create(data).then(res => navigate('/employees'))
                                }else {
                                    toast.error("Please Enter Your Phone No.", {
                                        duration: 3000,
                                        position: 'top-center',
                                        id: 1
                                    })
                                }
                            }else {
                                toast.error("Please Enter Your Age", {
                                    duration: 3000,
                                    position: 'top-center',
                                    id: 1
                                })
                            }
                        }else {
                            toast.error("Please Enter Your Gender", {
                                duration: 3000,
                                position: 'top-center',
                                id: 1
                            })
                        }
                    }else {
                        toast.error("Please Enter Iqama No.", {
                            duration: 3000,
                            position: 'top-center',
                            id: 1
                        })
                    }
                }else {
                    toast.error("Please Select Designation", {
                        duration: 3000,
                        position: 'top-center',
                        id: 1
                    })
                }
            }else {
                toast.error("Please Select Company Name", {
                    duration: 3000,
                    position: 'top-center',
                    id: 1
                })
            }
        }else {
            toast.error("Please Enter Your Name", {
                duration: 3000,
                position: 'top-center',
                id: 1
            })
        }
    }

    return (
        <PortalLayout>
            <h1 className='text-center text-[25px] font-[800] mt-5 uppercase'>{edit ? "Edit Employee" : "Add Employee"}</h1>
            <div className="w-[90%] m-auto bg-white shadow-md rounded-xl px-[10rem] pt-10 pb-8 flex flex-col my-5">
                <div className="-mx-3 mt-[-1.2rem] mb-6">
                    <div className="w-[100%] px-3 mb-6 md:mb-0 mt-5">
                        <label className="text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4">
                            Name
                        </label>
                        <input type="text" name="title" id="floating_email" onChange={(e) => setData(prevState => ({...prevState, name: e.target.value}))}
                               className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer"
                               placeholder="Enter Your Name"/>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mb-6 mt-[-12px]">
                    <div className="md:w-[100%] ">
                        <label className={`${style}`}>
                            Company
                        </label>
                        <Select
                            onChange={(v) => setCompany(v)}
                            value={company}
                            options={companies.map(value => {
                                return {
                                    name: value.name,
                                    id: value.id
                                }
                            })}
                            getOptionValue={(e) => e.id}
                            getOptionLabel={(e) => e.name}
                        />
                    </div>
                    <div className="md:w-[100%] ">
                        <label className={`${style}`}>
                            Designation
                        </label>
                        <Select
                            onChange={(v) => setDesignation(v)}
                            value={designation}
                            options={designations.map(value => {
                                return {
                                    name: value.name,
                                    id: value.id
                                }
                            })}
                            getOptionValue={(e) => e.id}
                            getOptionLabel={(e) => e.name}
                        />
                    </div>
                </div>
                <div className=" mt-[-1.2rem] mb-6">
                    <div className="md:w-[100%] ">
                        <label className={`${style}`}>
                            Iqama
                        </label>
                        <input type="text" id="floating_email" onChange={(e) => setData(prevState => ({...prevState, iqama: e.target.value}))}
                               className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer"
                               placeholder="Enter Iqama"/>
                    </div>
                </div>
                <div className=" mt-[-1.2rem] mb-6">
                    <div className="md:w-[100%] ">
                        <label className={`${style}`}>
                            Gender
                        </label>
                        <select
                            value={data.gender}
                            name="gender"
                            onChange={(e) => setData(prevState => ({...prevState, gender: e.target.value}))}
                            className={`pl-4 block py-[9px] w-full text-sm bg-gray-50 rounded-[9px] border-[1px]`}
                            id="grid-state">
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-5 mt-2'>
                    <div className="-mx-3 mt-[-1.2rem] mb-6">
                        <div className="w-[100%] px-3 mb-6 md:mb-0">
                            <label className=" tracking-wide text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4">
                                Age
                            </label>
                            <input type="number" name="age" id="age" onChange={(e) => setData(prevState => ({...prevState, age: e.target.value}))}
                                   className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer"
                                   placeholder="Enter Age"/>
                        </div>
                    </div>
                    <div className="-mx-3 mt-[-1.2rem] mb-6">
                        <div className="w-[100%] px-3 mb-6 md:mb-0">
                            <label className=" tracking-wide text-grey-darker text-[0.7rem] font-[600] mb-[3px] ml-4">
                                Phone
                            </label>
                            <input type="text" id="phone" onChange={(e) => setData(prevState => ({...prevState, phone: e.target.value}))}
                                   className="pl-4 py-[9px] px-0 w-full text-sm text-gray-900 bg-gray-50 rounded-[9px] border-[0.7px] border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-black peer"
                                   placeholder="Enter Contact Number "/>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <button onClick={() => submit()}
                        className='bg-black hover:font-bold text-white font-[600] py-2 px-[3rem] mt-4 rounded-lg cursor-pointer'>Submit
                    </button>
                </div>
            </div>
        </PortalLayout>
    )
}

export default EmployeesAddEdit
