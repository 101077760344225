import React, {useEffect, useState} from 'react';
import Select from "react-select";
import PortalLayout from "../../layouts/PortalLayout";
import designationService from "../../api/services/DesignationService";
import workerTrainingService from "../../api/services/WorkerTrainingService";
import moment from "moment";
import {useLocation} from "react-router-dom";

const EmployeeTrainings = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(true)
    const [worker, setWorker] = useState(0)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        console.log(params.get("id"))
        setWorker(Number(params.get("id")))
    }, [params]);

    useEffect(() => {
        if (worker) {
            setLoading(true)
            const fetchTrainings = async () => {
                const response = await workerTrainingService.fetchAll(worker);
                setData(response.trainings);
                setLoading(false)
            };
            fetchTrainings();
        }
    }, [reload, worker]);

    const startTraining = (id) => {
        workerTrainingService.create({worker: worker, training: id}).then(res => {
            const val = res.workerTraining
            workerTrainingService.start(val.id).then(res => {
                if (res.success) {
                    setReload(!reload)
                }
            })
        })
    }

    const endTraining = (id) => {
        workerTrainingService.end(id).then(res => {
            if (res.success) {
                setReload(!reload)
            }
        })
    }

    return (
        <PortalLayout>
            <h1 className='text-center text-[25px] font-[800] mt-5 uppercase'>{"Employee Training"}</h1>
            <div className="w-[90%] m-auto bg-white shadow-md rounded-xl px-[8rem] pt-10 pb-8 flex flex-col my-5">
                <div className={`grid grid-cols-2 gap-2`}>
                    {data.map(value => (
                        <div className={`flex w-full bg-gray-100 rounded-lg px-5 py-8 justify-center items-center`}>
                            <p className={`font-bold`}>{value.name}</p>
                            <div className={`flex flex-col ml-auto gap-1 h-12 items-center justify-center`}>
                                {value.end ?
                                    <div className={`text-center`}>
                                        <button
                                            className={`w-24 border-green-800 border-[1px] rounded-md text-green-800 pt-[3px] pb-[5px] font-bold`}>Trained
                                        </button>
                                        <p className={`text-[10px] text-gray-700 mt-1`}>{moment(value.end).format("ll")}</p>
                                    </div>
                                    :
                                    <>
                                        {value.start ?
                                            <button onClick={() => endTraining(value.done)}
                                                    className={`w-24 bg-red-700 rounded-md text-white pt-[3px] pb-[5px]`}>End</button>
                                            :
                                            <button onClick={() => startTraining(value.id)}
                                                    className={`w-24 bg-green-700 rounded-md text-white pt-[3px] pb-[5px]`}>Start</button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </PortalLayout>
    );
};

export default EmployeeTrainings;
