import axiosInstance from "./axiosInstance";

const workerTrainingService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/workerTraining/create/', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async (id) => {
        try {
            const response = await axiosInstance.get('/workerTraining/trainings/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    start: async (id) => {
        try {
            const response = await axiosInstance.put('/workerTraining/start/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    end: async (id) => {
        try {
            const response = await axiosInstance.put('/workerTraining/end/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default workerTrainingService
