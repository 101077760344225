import axiosInstance from "./axiosInstance";

const workerService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/workers/create', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    update: async (payload) => {
        try {
            const response = await axiosInstance.put('/workers/update', payload)
            return response.data
        } catch (error) {
            throw error
        }
    },
    fetch: async (id) => {
        try {
            const response = await axiosInstance.get('/workers/single/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchDetails: async (id) => {
        try {
            const response = await axiosInstance.get('/workers/details/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/workers/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/workers/delete/' + id)
            return response.data
        } catch (error) {
            throw error.response.data
        }
    }
}

export default workerService
