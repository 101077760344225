import React, {forwardRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {FaUserAlt, FaRegBuilding} from "react-icons/fa";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import {MdOutlineVerified} from "react-icons/md";
import {FaRegUser} from "react-icons/fa6";

const Sidebar = forwardRef(({}, ref) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div
            ref={ref}
            className="mt-[-4rem] fixed w-56 h-full bg-white rounded-r-[1.4rem] shadow-sm max-md:w-[40%] overflow-auto no-scrollbar">
            <div className="flex flex-col mt-[2rem]">
                <div
                    onClick={() => {
                        if (location.pathname !== "/employees") {
                            navigate("/employees");
                        }
                    }}>
                    <div
                        className={`pl-7 py-3 mx-5 rounded text-center cursor-pointer mb-1 flex items-center transition-colors 
                    ${
                            location.pathname === "/employees"
                                ? "bg-black text-white"
                                : "text-black font-[600] hover:text-white hover:bg-black"
                        }`}>
                        <div className="mr-2">
                            <FaUserAlt className="h-5 w-5"/>
                        </div>
                        <div>
                            <p className="font-bold">Employees</p>
                        </div>
                    </div>
                </div>

                {/*Company*/}
                <div
                    onClick={() => {
                        if (location.pathname !== "/companies") {
                            navigate("/companies");
                        }
                    }}>
                    <div
                        className={`pl-7 py-3 mx-5 rounded text-center cursor-pointer mb-1 flex items-center transition-colors 
                    ${
                            location.pathname === "/companies"
                                ? "bg-black text-white"
                                : "text-black font-[600] hover:text-white hover:bg-black"
                        }`}>
                        <div className="mr-2">
                            <FaRegBuilding className="h-5 w-5"/>
                        </div>
                        <div>
                            <p className="font-bold">Companies</p>
                        </div>
                    </div>
                </div>


                {/*Trainings*/}
                <div
                    onClick={() => {
                        if (location.pathname !== "/trainings") {
                            navigate("/trainings");
                        }
                    }}>
                    <div
                        className={`pl-7 py-3 mx-5 rounded text-center cursor-pointer mb-1 flex items-center transition-colors 
                    ${
                            location.pathname === "/trainings"
                                ? "bg-black text-white"
                                : "text-black font-[600] hover:text-white hover:bg-black"
                        }`}>
                        <div className="mr-2">
                            <LiaChalkboardTeacherSolid className="h-5 w-5"/>
                        </div>
                        <div>
                            <p className="font-bold">Trainings</p>
                        </div>
                    </div>
                </div>

                {/*Company*/}
                <div
                    onClick={() => {
                        if (location.pathname !== "/designations") {
                            navigate("/designations");
                        }
                    }}>
                    <div
                        className={`pl-7 py-3 mx-5 rounded text-center cursor-pointer mb-1 flex items-center transition-colors 
                    ${
                            location.pathname === "/designations"
                                ? "bg-black text-white"
                                : "text-black font-[600] hover:text-white hover:bg-black"
                        }`}>
                        <div className="mr-2">
                            <MdOutlineVerified className="h-5 w-5"/>
                        </div>
                        <div>
                            <p className="font-bold">Designations</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Sidebar
